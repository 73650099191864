import React, { useState } from 'react';
import styled from '@emotion/styled';

import { FaPlus, FaQuoteLeft, FaQuoteRight, FaRegPlayCircle } from 'react-icons/fa';
import Img, { FixedObject, FluidObject } from 'gatsby-image';
import { colors, dimensions, fonts, heights, variants } from '../../styles/variables';
import { Button, Modal, RenderMarkdown, Tooltip, YoutubeEmbed } from '../atoms';
import Carousel from './Carousel';

type Image = {
  localFile: {
    childImageSharp: {
      fluid: FluidObject;
    };
  };
};

export type ActualityCategoryProps = {
  id: string;
  name: string;
  strapiId: number;
  icon: {
    localFile: {
      childImageSharp: {
        fixed: FixedObject;
      };
    };
  };
};

export type ArticleProps = {
  id: string | number;
  strapiId: number;
  title: string;
  content: string;
  image: Image;
  gallery: Image[];
};

export type ActualiteLinkProps = {
  id: number;
  title: string;
  link: string;
  article: ArticleProps;
  type: string;
};

export type ActualiteCardProps = {
  title: string;
  content: string;
  type: string;
  headerImage: Image;
  media: string;
  actuality_categories: { id: number }[];
  expert_categories: { id: number }[];
  categories: ActualityCategoryProps[];
  selectedCategory: number;
  links: ActualiteLinkProps[];
  articles: ArticleProps[];
};

const Title = styled.h3`
  font-size: 22px;
  line-height: 27px;
  margin: 0;
  padding: 13px 18px;
  position: relative;
  text-shadow: 1px 1px 0px ${colors.white};
`;

const CitationWrapper = styled.blockquote`
  margin: 0;
`;

const Citation = styled.span`
  font-size: 28px;
  line-height: 34px;
  font-weight: 300;
`;

const CustomQuoteLeft = styled(FaQuoteLeft)`
  display: block;
  font-size: 35px;
  color: ${colors.purple};
  transition: all 0.3s ease-in-out;
`;

const CustomQuoteRight = styled(FaQuoteRight)`
  font-size: 20px;
  margin-left: 10px;
  transform: translateY(10px);
  color: ${colors.white};
`;

const Body = styled.div`
  text-shadow: 1px 1px 0px ${colors.white};
  flex-grow: 1;
`;

const BackgroundImage = styled(Img)`
  position: absolute !important;
  z-index: -1;
  object-fit: cover;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

const OverlayBackground = styled.div`
  position: absolute;
  z-index: -1;
  background: rgba(255, 255, 255, 0.7);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

const YoutubeWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
`;

const YoutubeOverlay = styled.div`
  background-color: rgba(98, 49, 138, 0.48);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
`;

const YoutubeThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const YoutubePlayIcon = styled(FaRegPlayCircle)`
  color: ${colors.white};
  font-size: 6rem;
  transition: all 0.3s ease-in-out;
`;

const Footer = styled.div`
  background-color: ${colors.lightLila};
  padding: 6px 15px;
  color: ${colors.purple};
  font-size: 14px;
  display: flex;
  flex-wrap;
  justify-content: space-between;
  align-items: center;
`;

const Card = styled.div<{ type: string; hasHeaderImage: boolean }>`
  margin: 10px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 0px 10px #62318a24;
  border: 1px solid ${colors.lila};
  width: 365px;
  max-width: 100%;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: all 0.3s ease-in-out;

  ${(props) =>
    props.type === 'texte' &&
    `
    // border: 1px solid ${colors.lila};
    ${Title}::after {
      background-color: ${props.hasHeaderImage ? colors.white : colors.darkLila};
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 1px;
      width: 110px;
    }

    ${Body} {
      padding: 10px 20px;

      p {
        display: flex;
      }

      a {
        display: flex;
        text-shadow: none;
        font-family: ${fonts.montserrat};
        font-weight: ${variants.regular};
        font-size: ${dimensions.text.p}px;
        background: ${colors.purple};
        color: ${colors.white};
        border: none;
        border-radius: 5px;
        height: ${heights.button.medium}px;
        line-height: 26px;
        letter-spacing: 0;
        cursor: pointer;
        padding: 0 ${dimensions.space.button.medium.x}px;
        text-align: center;
        position: relative;
        display: flex;
        align-items: center;
        transition: all 0.3s ease-in-out;
        text-decoration: none !important;

        &:hover {
          background-color: ${colors.gold};
        }
      }
    }

    ${
      !props.hasHeaderImage &&
      `
      background-color: ${colors.white};
      `
    }
  `}

  ${(props) =>
    props.type === 'video' &&
    `
    // border: 1px solid #391557;

    ${Title} {
      background-color: ${colors.purple};
      color: ${colors.white};
    }

    ${Body}:hover {
      ${YoutubeOverlay} {
        opacity: 0;
      }

      ${YoutubePlayIcon} {
        transform: translateY(-100%);
      }
    }
  `}

  ${(props) =>
    props.type === 'citation' &&
    `
    background-color: #FEE6C4;
    // border: 1px solid #FEE6C4;

    &:hover {
      background-color: #FCA01F;
      // border: 1px solid #FCA01F;

      ${CustomQuoteLeft} {
        color: ${colors.white};
        opacity: .3;
      }
    }

    ${Body} {
      padding: 10px 20px;
    }
  `}
`;

const ActualiteLinkButtonIcon = styled(FaPlus)`
  transition: all 0.3s ease-in-out;
`;

const ActualiteLinkButton = styled(Button)`
  margin-top: 10px;

  &:hover ${ActualiteLinkButtonIcon} {
    transform: scale(1.3) rotate(180deg);
  }
`;

const ModalFooter = styled.div`
  display: flex;
  flex-wrap;
  justify-content: flex-end;
  align-items: center;
  color: ${colors.purple};
  font-size: 14px;
  column-gap: 5px;
`;

const FooterText = styled.div``;

const FooterCategories = styled.div`
  display: flex;
  flex-wrap;
  gap: 10px;
`;

const CarouselImg = styled(Img)`
  max-height: 60vh;
  width: 100%;

  img {
    object-fit: contain !important;
  }
`;

const ActualiteLink = ({ type, title, link, article, currentCategories }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <ActualiteLinkButton
        size="medium"
        color="purple"
        hovercolor="gold"
        variant="rectangular"
        rightIcon={!title && <ActualiteLinkButtonIcon />}
        onClick={() => (type === 'internal' ? setShow(true) : window.open(link, '_blank'))}
      >
        {title || 'En savoir'}
      </ActualiteLinkButton>
      {type === 'internal' && (
        <Modal
          show={show}
          onClose={() => setShow(false)}
          title={article.title}
          headerImage={article.image}
          footer={
            <ModalFooter>
              <FooterText>Classé dans :</FooterText>
              <FooterCategories>
                {currentCategories.map((o) => (
                  <Tooltip key={o.strapiId} placement="right" content={o.name}>
                    <Img fixed={o.icon.localFile.childImageSharp.fixed} />
                  </Tooltip>
                ))}
              </FooterCategories>
            </ModalFooter>
          }
        >
          {article.gallery && (
            <Carousel noArrows>
              {article.gallery.map((o) => (
                <CarouselImg key={o} fluid={o.localFile.childImageSharp.fluid} />
              ))}
            </Carousel>
          )}
          {article.content && <RenderMarkdown linkTarget="_blank">{article.content}</RenderMarkdown>}
        </Modal>
      )}
    </>
  );
};

export default function ActualiteCard({
  title,
  content,
  type,
  headerImage,
  media,
  links,
  actuality_categories,
  expert_categories,
  categories,
  selectedCategory,
  articles,
}: ActualiteCardProps) {
  // Categories of the current actuality
  const currentCategoriesLink = actuality_categories ? actuality_categories : expert_categories;
  const currentCategories = categories.filter((category) =>
    currentCategoriesLink.map((o) => o.id).includes(category.strapiId),
  );

  // If there is a selected category and the current actuality doesn't have this category, we don't render it
  if (selectedCategory !== null && !currentCategories.map((o) => o.strapiId).includes(selectedCategory)) {
    return <></>;
  }

  // Regex to get video id from youtube url
  const video = media ? media.match(/.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\\&\\?]*).*/) : null;
  const videoId = video && video[1] ? video[1] : null;

  return (
    <Card type={type} hasHeaderImage={!!headerImage}>
      {type !== 'citation' && <Title>{title}</Title>}
      <Body>
        <div>
          <RenderMarkdown linkTarget="_blank">{content}</RenderMarkdown>
        </div>
        {headerImage && (
          <>
            <BackgroundImage aria-hidden fluid={headerImage.localFile.childImageSharp.fluid} />
            <OverlayBackground aria-hidden />
          </>
        )}
        {type === 'citation' && (
          <CitationWrapper>
            <CustomQuoteLeft />
            <Citation>{title}</Citation>
            &nbsp;
            <CustomQuoteRight />
          </CitationWrapper>
        )}
        {type === 'video' && videoId && (
          <YoutubeWrapper /* onClick={() => setShow(true)} */>
            <YoutubeThumbnail src={`https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`} alt="Youtube thumbnail" />
            <YoutubeOverlay>
              <YoutubePlayIcon />
            </YoutubeOverlay>
          </YoutubeWrapper>
        )}
        {/* {type !== 'video' && (
          <LearnMoreButton
            size="medium"
            color="purple"
            hovercolor="gold"
            variant="rectangular"
            rightIcon={<LearnMoreButtonIcon />}
            onClick={() => setShow(true)}
            style={{ marginTop: 10, marginLeft: 'auto' }}
          >
            En savoir
          </LearnMoreButton>
        )} */}
        {type !== 'video' && (
          <>
            {links.map((o) =>
              o.type === 'internal' ? (
                <ActualiteLink
                  {...o}
                  article={articles.find((a) => a.strapiId === o.article.id)}
                  currentCategories={currentCategories}
                />
              ) : (
                <ActualiteLink {...o} currentCategories={currentCategories} />
              ),
            )}
          </>
        )}
      </Body>
      <Footer>
        <FooterText>
          <div>Classé dans :</div>
        </FooterText>
        <FooterCategories>
          {currentCategories.map((o) => (
            <Tooltip key={o.strapiId} placement="right" content={o.name}>
              <Img fixed={o.icon.localFile.childImageSharp.fixed} />
            </Tooltip>
          ))}
        </FooterCategories>
      </Footer>
      {/* <Modal
        show={show}
        onClose={() => setShow(false)}
        title={title}
        headerImage={headerImage}
        footer={
          <ModalFooter>
            <FooterText>Classé dans :</FooterText>
            <FooterCategories>
              {currentCategories.map((o) => (
                <Tooltip key={o.strapiId} placement="right" content={o.name}>
                  <Img fixed={o.icon.localFile.childImageSharp.fixed} />
                </Tooltip>
              ))}
            </FooterCategories>
          </ModalFooter>
        }
      >
        {(type === 'texte' || type === 'citation') && <RenderMarkdown>{content}</RenderMarkdown>}
        {type === 'video' && <YoutubeEmbed embedId={videoId} />}
      </Modal> */}
    </Card>
  );
}
